<script>
    import Scroll from '~/components/stateless/Scroll.svelte'

    export let label
    export let onClose
</script>

<div class="fiestaview">
    <Scroll>
        <div class="subscroll">
            <slot />
        </div>
    </Scroll>

    <div class="header">
        <div class="headercontent">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div class="back" on:click={onClose}>
                <img alt="back" src="/img/back.svg" width="32" height="32" />
            </div>
            <div class="headerlabel">{label}</div>
        </div>
    </div>
</div>

<style>
    .fiestaview {
        z-index: 2;
        /* transition: left 0.25s ease; */
        position: absolute;
        top: 0;
        background: var(--COLOR_BACKGROUND);
        width: 100%;
        height: 100%;
    }

    .header {
        padding: 0 var(--MARGIN);
        background: rgba(0, 0, 0, 0.7);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
    }

    .headercontent {
        display: flex;
        align-items: center;
        position: relative;
        padding: var(--MARGIN) 0;
    }

    .headerlabel {
        flex: 1;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: white;
    }

    .back {
        position: absolute;
        margin-right: 10px;
        height: 100%;
        width: 26px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .subscroll {
        padding-top: calc(var(--MARGIN) * 4);
    }
</style>
