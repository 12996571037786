export const LOCALSTORAGE = {
    LANGUAGE: 'language',
    TOKEN: 'token',
    NAME: 'name',
}

export const NOTIFICATION_TYPE = {
    SUCCESS: 'success',
    ERROR: 'error',
}
