<script>
    // https://web.dev/virtualkeyboard/
    // navigator.virtualKeyboard.overlaysContent = true

    import { createEventDispatcher } from 'svelte'

    export let height = 'auto'
    export let open = false

    const dispatch = createEventDispatcher()

    function onClose(e) {
        dispatch('close', e)
    }

    function onClickContent(e) {
        e.stopPropagation()
    }
</script>

<div
    class="background {open && 'open'}"
    on:click={onClose}
    style="--height:{height}"
>
    <div class="container-out">
        <div class="container" on:click={onClickContent}>
            <div class="content">
                <slot />
            </div>
        </div>
    </div>
</div>

<style>
    .background {
        transition: all 0.25s ease;
        visibility: hidden;
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        backdrop-filter: blur(0px);
    }

    .container-out {
        transition: bottom 0.25s ease;
        width: 100%;
        height: 100%;
        bottom: -100%;
        position: absolute;
        display: flex;
        align-items: flex-end;
    }

    .container {
        background: var(--COLOR_BACKGROUND);
        height: var(--height);
        width: 100%;
        border-radius: 30px 30px 0 0;
    }

    .open {
        visibility: visible;
        background: var(--COLOR_BACKGROUND_BLUR);
        backdrop-filter: blur(5px);
    }

    .open .container-out {
        bottom: 0;
    }

    .content {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
    }

    .content > div {
        width: 100%;
    }
</style>
