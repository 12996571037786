<script>
    import { translate } from '~/locale/index.js'
    import { VIEW, changeRoute, getRoute } from '~/router.js'

    import Container from '~/components/stateless/Container.svelte'
    import Button from '~/components/stateless/Button.svelte'
</script>

<Container>
    <div class="center">
        <div class="noexists-oops">Oops...</div>
        <div class="noexists-text">{translate('Fiesta not found')}.</div>
        <div class="noexists-image">
            <img src="/img/fiestaover.svg" alt="fiesta not found" />
        </div>
        <div class="noexists-button">
            <Button on:click={() => changeRoute(VIEW.HOME)}
                >{translate('Back to home')}</Button
            >
        </div>
    </div>
</Container>

<style>
    .center {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        padding-left: var(--MARGIN_CONTAINER);
        padding-right: var(--MARGIN_CONTAINER);
        flex-direction: column;
    }
    .noexists-oops {
        text-align: center;
        color: white;
        font-weight: bold;
        font-size: 40px;
    }
    .noexists-text {
        text-align: center;
        color: white;
        font-size: 20px;
    }
    .noexists-image img {
        height: auto;
        width: 100%;
    }
</style>
