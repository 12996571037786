<script>
    import User from '~/components/stateless/User.svelte'

    export let image = ''
    export let name = ''
    export let artists = ''
    export let user = ''
    export let me = false
</script>

<div class="track">
    <div class="left">
        <div class="image">
            <img alt={name} src={image} />
        </div>
        <div class="info">
            <div class="name">{name}</div>
            <div class="artists">{artists}</div>
            <User name={user} {me} />
        </div>
    </div>
</div>

<style>
    .track {
        width: calc(100% - 80px);
        display: flex;
        justify-content: space-between;
        padding: var(--MARGIN) 0;
    }

    .left {
        display: flex;
        height: 65px;
        width: 100%;
    }

    .image {
        margin-right: var(--MARGIN);
    }

    .image img {
        width: 65px;
        height: 65px;
        border-radius: 3px;
    }

    .info {
        width: 100%;
        position: relative;
        top: -3px;
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .name {
        width: 100%;
        color: white;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .artists {
        width: 100%;
        color: white;
        font-weight: 200;
        font-size: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
</style>
