<script>
    import { createEventDispatcher } from 'svelte'
    const dispatch = createEventDispatcher()

    export let className = ''

    function onClick(e) {
        dispatch('click', e)
    }
</script>

<div class="button {className}" on:click={onClick}>
    <slot />
</div>

<style>
    .button {
        line-height: 60px;
        font-size: 18px;
        text-align: center;
        border-top: 1px solid rgba(100, 100, 100, 0.2);
    }

    .button:active {
        background-color: rgba(100, 100, 100, 0.2);
    }

    .close {
        line-height: 75px;
        font-size: 22px;
        text-align: center;
    }

    .red {
        color: var(--COLOR_ERROR);
    }
</style>
