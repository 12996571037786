<script>
    import { createEventDispatcher } from 'svelte'

    const dispatch = createEventDispatcher()

    export let disabled = false

    function onClick(e) {
        dispatch('click', e)
    }
</script>

<button on:click={onClick} {disabled}>
    <slot />
</button>

<style>
    button {
        border: 0;
        background: linear-gradient(
            var(--COLOR_BUTTON) 0%,
            var(--COLOR_BUTTON2)
        );
        color: white;
        border: 0;
        font-family: 'Fira Sans';
        border-radius: 100px;
        font-weight: bold;
        font-size: 18px;
        width: 100%;
        height: 55px;
        padding: 0 20px;
    }

    button:active {
        background: var(--COLOR_BUTTON2);
        padding-top: 5px;
        box-shadow: 0px 5px inset rgb(0 0 0 / 20%);
    }

    button:disabled {
        background: var(--COLOR_BUTTON_DISABLED);
        color: var(--COLOR_TEXT_GREY);
    }
</style>
