<script>
    import { getUrlAuth } from '~/../../shared/spotify.js'
    import { SPOTIFY_CLIENT_ID } from '~/../../shared/secret.js'
    import { isValidCode } from '~/../../shared/is.js'

    import { onConnected, createFiesta, createNotification } from '~/store.js'
    import { setHref, getLocationObject } from '~/browser.js'
    import { server } from '~/server.js'
    import { changeRoute, VIEW } from '~/router.js'
    import { translate } from '~/locale/index.js'
    import { NOTIFICATION_TYPE } from '~/const.js'

    import Container from '~/components/stateless/Container.svelte'
    import Desktop from '~/components/stateless/Desktop.svelte'
    import Field from '~/components/stateless/Field.svelte'
    import Button from '~/components/stateless/Button.svelte'

    let code = ''
    let invalid_code = false

    onConnected(async () => {
        const u = new URL(getLocationObject())
        const spotify_code = u.searchParams.get('code')
        const spotify_action = u.searchParams.get('state')

        // We must create a new fiesta
        if (typeof spotify_code == 'string' && spotify_action === 'create') {
            try {
                await createFiesta({ spotify_code })
            } catch (e) {
                const message = translate(e.message || e.error)
                createNotification({
                    message,
                    type: NOTIFICATION_TYPE.ERROR,
                })
            }
        }
    })

    async function gotoFiesta(code) {
        const exists = await server.existsFiesta({ code })
        if (exists) {
            changeRoute(VIEW.FIESTA, { code })
        } else {
            invalid_code = true
            createNotification({
                message: `${translate('Fiesta not found')}.`,
                type: NOTIFICATION_TYPE.ERROR,
            })
        }
    }

    function authSpotify() {
        const location = getLocationObject()
        const url = getUrlAuth({
            client_id: SPOTIFY_CLIENT_ID,
            redirect_uri: `${location.protocol}//${location.host}/callback`,
            state: 'create',
            scopes: [
                'user-read-private',
                'user-read-currently-playing',
                'user-read-playback-state',
                // 'user-read-recently-played',
                'user-modify-playback-state',
            ],
        })
        setHref(url)
    }
</script>

<Container>
    <Desktop maxwidth={400}>
        <div class="content">
            <div class="logo"><img alt="logo" src="/img/logo.svg" /></div>
            <div class="form">
                <Field>
                    <!-- <Input
                    id="inputchangename"
                    type="text"
                    maxlength="4"
                    bind:value={code}
                    on:input={() => (invalid_code = false)}
                /> -->

                    <input
                        class={`input ${invalid_code ? 'invalid' : ''}`}
                        type="text"
                        maxlength="4"
                        bind:value={code}
                        on:input={() => (invalid_code = false)}
                    />
                </Field>

                <Field>
                    <Button
                        disabled={!isValidCode(code.toUpperCase())}
                        on:click={() => gotoFiesta(code.toUpperCase())}
                        >{translate('JOIN')}</Button
                    >
                </Field>

                <div class="or">
                    <div class="orline" />
                    <div class="ortext">{translate('Or')}</div>
                    <div class="orline" />
                </div>

                <Button on:click={authSpotify}
                    >{translate('CREATE FIESTA')}</Button
                >
                <div class="premium">
                    {translate('Spotify premium is required')}.
                </div>
            </div>
        </div>
    </Desktop>
</Container>

<style>
    .content {
        padding: var(--MARGIN_CONTAINER);
        margin: 0 auto;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .logo {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
    }

    .logo img {
        width: 100%;
    }

    .form {
    }

    .input {
        background: white;
        color: var(--COLOR_BUTTON2);
        /* box-shadow: 4px 4px 1px inset var(--COLOR_SHADOW_WHITE); */
        border: 0;
        font-family: 'Fira Code', monospace;
        border-radius: 100px;
        font-weight: bolder;
        font-size: 35px;
        width: 100%;
        height: 55px;
        text-align: center;
        outline: none;
        text-transform: uppercase;
        letter-spacing: 3px;
    }

    .or {
        display: flex;
        justify-content: center;
        width: 180px;
        margin: 5px auto;
    }

    .orline {
        border-top: 2px solid var(--COLOR_TEXT_GREY);
        flex: 1 1 0%;
        position: relative;
        top: 13px;
        opacity: 0.5;
    }

    .ortext {
        margin: 0px 14px;
        color: var(--COLOR_TEXT_GREY);
        font-size: 12px;
        opacity: 0.6;
        line-height: 28px;
    }

    .premium {
        color: var(--COLOR_TEXT_GREY);
        font-size: 12px;
        opacity: 0.6;
        line-height: 28px;
        text-align: center;
    }

    .invalid {
        color: var(--COLOR_ERROR);
        border: 3px solid var(--COLOR_ERROR);
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }

    @keyframes shake {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0);
        }
        20%,
        80% {
            transform: translate3d(2px, 0, 0);
        }
        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0);
        }
        40%,
        60% {
            transform: translate3d(4px, 0, 0);
        }
    }
</style>
