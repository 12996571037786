<script>
    import { onMount } from 'svelte'
    import { TYPE } from 'dop'
    import { SERVER_ERROR } from '~/../../shared/error.js'
    import { PLAYER_FORWARD_BACKWARD } from '~/../../shared/const.js'
    import { timeToSecondsMinutes } from '~/../../shared/utils.js'
    import { isValidToUseThePlayer } from '~/../../shared/is.js'
    import { translate } from '~/locale/index.js'
    import { VIEW, FIESTASUBVIEW, changeRoute, getRoute } from '~/router.js'
    import {
        state,
        getToken,
        onPatch,
        joinFiesta,
        likeTrack,
        unlikeTrack,
        play,
        pause,
        next,
        previous,
        forward,
        backward,
        volumeup,
        volumedown,
        setGlobalState,
    } from '~/store.js'

    import FiestaViewSearch from '~/components/views/FiestaViewSearch.svelte'
    import FiestaViewNotfound from '~/components/views/FiestaViewNotfound.svelte'
    import FiestaViewHistory from '~/components/views/FiestaViewHistory.svelte'
    import FiestaViewPeople from '~/components/views/FiestaViewRanking.svelte'
    import FiestaListPlaylist from '~/components/partials/FiestaListPlaylist.svelte'
    import FiestaListHistory from '~/components/partials/FiestaListHistory.svelte'

    import Container from '~/components/stateless/Container.svelte'
    import Scroll from '~/components/stateless/Scroll.svelte'
    import Desktop from '../stateless/Desktop.svelte'
    import TrackPlaying from '~/components/stateless/TrackPlaying.svelte'
    import TrackDetail from '~/components/views/TrackDetail.svelte'
    import ChangeName from '~/components/views/ChangeName.svelte'
    import Share from '~/components/views/Share.svelte'
    import Menu from '~/components/views/Menu.svelte'

    const { params } = getRoute()
    const code = params.code
    let subview = params.subview
    let exists = true
    let trackdetail = null
    let changename_open = false
    let share_open = false
    let profile_open = false
    let fiesta = {}

    onMount(() => {
        join()
    })

    onPatch(['connected'], () => {
        join()
    })

    onPatch(['location'], () => {
        const { params } = getRoute(state.location)
        subview = params.subview
    })

    onPatch(
        ({ path }) => path[0] === 'fiesta',
        (patch) => {
            fiesta = state.fiesta
        }
    )

    async function join() {
        if (state.connected) {
            try {
                const { user_id, fiesta, ask_for_name } = await joinFiesta({
                    code,
                    rpc: onServerPatch,
                })

                changename_open = ask_for_name

                setGlobalState({
                    user_id,
                    fiesta: TYPE.Replace(fiesta),
                })
            } catch (e) {
                if (e.error === SERVER_ERROR.INVALID_CODE_FIESTA.error) {
                    exists = false
                }
            }
        }
    }

    function onServerPatch(fiesta) {
        setGlobalState({ fiesta })
    }

    function onLikeUnlike(e, track) {
        const { uri } = track
        const token = getToken()
        // const { code } = getRoute().params

        track.likes.includes(state.user_id)
            ? unlikeTrack({ code, token, uri })
            : likeTrack({ code, token, uri })

        e.stopPropagation()
    }

    function onOpenSearch() {
        changeRoute(VIEW.FIESTA, { code, subview: FIESTASUBVIEW.SEARCH })
    }
    function onCloseSubview() {
        changeRoute(VIEW.FIESTA, { code })
    }

    function onClickTrack(track) {
        trackdetail = track
    }

    function onClickShare() {
        share_open = true
    }

    async function onPlay() {
        const token = getToken()
        await play({ code, token })
        fiesta.playback.is_playing = true
    }

    async function onPause() {
        const token = getToken()
        await pause({ code, token })
        fiesta.playback.is_playing = false
    }

    async function onNext() {
        const token = getToken()
        await next({ code, token })
    }

    async function onPrevious() {
        const token = getToken()
        await previous({ code, token })
    }

    async function onBackward() {
        const token = getToken()
        await backward({ code, token })
        fiesta.playback.progress_ms -= PLAYER_FORWARD_BACKWARD
    }

    async function onForward() {
        const token = getToken()
        await forward({ code, token })
        fiesta.playback.progress_ms += PLAYER_FORWARD_BACKWARD
    }

    async function onVolumeup() {
        const token = getToken()
        await volumeup({ code, token })
    }

    async function onVolumedown() {
        const token = getToken()
        await volumedown({ code, token })
    }

    function getTracksByUser(user_id, tracks) {
        const t = Object.keys(tracks).map((uri) => tracks[uri])
        return t.filter((track) => track.user_id === user_id).length
    }
</script>

{#if !exists}
    <Desktop>
        <FiestaViewNotfound />
    </Desktop>
{:else if fiesta.hasOwnProperty('playback')}
    <Container>
        <Scroll>
            <Desktop>
                <div class="subscroll">
                    <div class="header">
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <div
                            class="button button-share"
                            on:click={onClickShare}
                        >
                            <div class="button-icon-share" />
                            <div class="button-text">{code}</div>
                        </div>
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                        <div
                            class="button button-profile"
                            on:click={() => (profile_open = true)}
                        >
                            <div class="button-icon-profile" />
                        </div>
                    </div>

                    {#if fiesta.active}
                        {#if !fiesta.playback.available_devices}
                            <div class="alert">
                                <div>
                                    <span>
                                        {translate(
                                            '${user} is not playing music',
                                            {
                                                user: fiesta.users[fiesta.admin]
                                                    .name,
                                            }
                                        )}
                                    </span>
                                </div>
                            </div>
                        {/if}

                        <div
                            class="playback"
                            style="--image:url({fiesta.playback.image})"
                        >
                            <div
                                class="playback-content {fiesta.playback
                                    .is_playing
                                    ? 'playing'
                                    : ''}"
                            >
                                <TrackPlaying
                                    name={fiesta.playback.name || ''}
                                    artists={(
                                        fiesta.playback?.artists || []
                                    ).join(', ')}
                                    me={fiesta.playback.user_id ===
                                        state.user_id}
                                    user={typeof fiesta.playback.user_id ==
                                    'string'
                                        ? fiesta.users[fiesta.playback.user_id]
                                              .name
                                        : ''}
                                />

                                <div class="equalizer">
                                    {#if fiesta.playback.is_playing}
                                        <div class="equalizer-back" />
                                        <div
                                            class="equalizer-front"
                                            style="--progress:{(fiesta.playback
                                                .progress_ms *
                                                100) /
                                                fiesta.playback.duration_ms}%"
                                        />
                                    {/if}
                                </div>

                                <div class="timeline">
                                    <div
                                        class="timeline-progress"
                                        style="--progress:{(fiesta.playback
                                            .progress_ms *
                                            100) /
                                            fiesta.playback.duration_ms}%"
                                    />
                                </div>

                                <div class="time">
                                    <div>
                                        {timeToSecondsMinutes(
                                            Math.round(
                                                (fiesta.playback?.progress_ms ||
                                                    0) / 1000
                                            )
                                        )}
                                    </div>
                                    <div>
                                        -{timeToSecondsMinutes(
                                            Math.round(
                                                ((fiesta.playback
                                                    ?.duration_ms || 0) -
                                                    (fiesta.playback
                                                        ?.progress_ms || 0)) /
                                                    1000
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    {:else}
                        <div class="fiestaover">
                            <div>
                                {translate(
                                    'Oops... ${user} has finished the fiesta',
                                    {
                                        user: fiesta.users[fiesta.admin].name,
                                    }
                                )}.
                            </div>
                            <div class="smalltext">
                                {translate(
                                    'All the data of this fiesta will be removed in ${hours} hours',
                                    {
                                        hours: Math.floor(
                                            (fiesta.expires - Date.now()) /
                                                3600000
                                        ),
                                    }
                                )}.
                            </div>
                        </div>
                    {/if}

                    {#if fiesta.active && fiesta.playback.available_devices && isValidToUseThePlayer(fiesta, state.user_id)}
                        <div id="player">
                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <div class="playerbtn" on:click={onPrevious}>
                                <img
                                    src="/img/playerprevious.svg"
                                    alt="previous"
                                />
                            </div>
                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <div class="playerbtn" on:click={onBackward}>
                                <img
                                    src="/img/playerbackward.svg"
                                    alt="backward"
                                />
                            </div>
                            <!-- <div class="playerbtn" on:click={onVolumedown}>
                                <img
                                    src="/img/playervolumedown.svg"
                                    alt="volumedown"
                                />
                            </div> -->
                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <div
                                class="playerbtnbig"
                                on:click={fiesta.playback.is_playing
                                    ? onPause
                                    : onPlay}
                            >
                                <img
                                    src={fiesta.playback.is_playing
                                        ? '/img/playerpause.svg'
                                        : '/img/playerplay.svg'}
                                    alt="play pause"
                                />
                            </div>
                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <!-- <div class="playerbtn" on:click={onVolumeup}>
                                <img
                                    src="/img/playervolumeup.svg"
                                    alt="volumeup"
                                />
                            </div> -->
                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <div class="playerbtn" on:click={onForward}>
                                <img
                                    src="/img/playerforward.svg"
                                    alt="forward"
                                />
                            </div>
                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <div class="playerbtn" on:click={onNext}>
                                <img src="/img/playernext.svg" alt="next" />
                            </div>
                        </div>
                    {/if}

                    {#if fiesta.active}
                        <FiestaListPlaylist
                            {fiesta}
                            {onClickTrack}
                            {onLikeUnlike}
                        />
                    {:else}
                        <div class="category-label">
                            {translate('Playlist')}
                        </div>
                        <FiestaListHistory {fiesta} {onClickTrack} />
                    {/if}
                </div>
            </Desktop>
        </Scroll>
    </Container>

    <Desktop>
        {#if fiesta.active}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div class="add" on:click={onOpenSearch}>
                <div
                    class="addbutton {!getTracksByUser(
                        state.user_id,
                        fiesta.tracks
                    ) && 'addbuttonanimated'}"
                >
                    <img alt="add" src="/img/add.svg" width="32" height="32" />
                </div>
            </div>
        {/if}

        <!-- VIEWS -->
        <!-- VIEWS -->
        <!-- VIEWS -->

        {#if subview === FIESTASUBVIEW.SEARCH}
            <FiestaViewSearch {code} on:close={onCloseSubview} />
        {:else if subview === FIESTASUBVIEW.HISTORY}
            <FiestaViewHistory
                {fiesta}
                {onClickTrack}
                on:close={onCloseSubview}
            />
        {:else if subview === FIESTASUBVIEW.RANKING}
            <FiestaViewPeople {fiesta} on:close={onCloseSubview} />
        {/if}

        <!-- DIALOGS -->
        <!-- DIALOGS -->
        <!-- DIALOGS -->

        <TrackDetail
            {fiesta}
            track={trackdetail}
            on:close={() => (trackdetail = null)}
        />

        <Menu
            {code}
            active={fiesta.active}
            name={fiesta.users[state.user_id]?.name}
            open={profile_open}
            {onClickShare}
            on:close={() => (profile_open = false)}
            on:changename={() => {
                profile_open = false
                changename_open = true
            }}
        />

        <Share {code} open={share_open} on:close={() => (share_open = false)} />

        <ChangeName
            {code}
            open={changename_open}
            on:close={() => (changename_open = false)}
        />
    </Desktop>
{/if}

<style>
    :root {
        --TRACK_HEIGHT: 110px;
    }

    .subscroll {
        padding-bottom: 90px;
    }

    .header {
        position: absolute;
        /* z-index: 1; */
        width: 100%;
        padding: var(--MARGIN);
        display: flex;
        justify-content: flex-end;
    }

    .header > div {
        margin-left: calc(var(--MARGIN));
    }

    .category-label {
        margin: 10px var(--MARGIN);
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        color: white;
        font-family: 'Fira Code';
        letter-spacing: 2px;
        opacity: 0.4;
    }

    #player {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--MARGIN) 0;
    }

    #player > div {
        width: 30px;
        height: 30px;
        margin: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.5s ease;
    }

    #player > div:active {
        transform: scale(0.8);
        transition: transform 0.1s ease;
    }

    #player img {
        width: 30px;
        height: 30px;
    }

    #player .playerbtnbig {
        background: white;
        border-radius: 50%;
        width: 55px;
        height: 55px;
    }

    .button {
        border-radius: 50%;
        background: var(--COLOR_BUTTON_BLUR);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button:active {
        background: rgba(255, 255, 255, 0.5);
    }

    .button-share {
        width: 115px;
        border-radius: 25px;
    }

    .button-icon-share {
        background: url(/img/share.svg) no-repeat center / 100%;
        width: 20px;
        height: 20px;
        opacity: 0.7;
    }

    .button-icon-profile {
        background: url(/img/dots.svg) no-repeat center / 100%;
        width: 25px;
        height: 25px;
        opacity: 0.7;
    }

    .button-text {
        font-family: 'Fira Code';
        font-size: 20px;
        margin-left: 5px;
        color: white;
        opacity: 0.7;
        letter-spacing: 1px;
    }

    .alert {
        /* width: 100%;
        display: flex;
        justify-content: center;
        align-items: center; */
        position: absolute;
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
    }

    .alert div {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 15px;
        color: white;
        background: var(--COLOR_ERROR);
        padding: 12px 0;
        border-right: 0;
        border-left: 0;
        margin: calc(var(--MARGIN) * 2) var(--MARGIN);
        border-radius: 30px;
        animation: blink-1 5s infinite both;
    }

    /* .alert span {
        display: block;
    } */

    @keyframes blink-1 {
        0%,
        50%,
        100% {
            opacity: 1;
        }
        25%,
        75% {
            opacity: 0.3;
        }
    }

    .playback {
        padding: 0 var(--MARGIN);
        /* padding-top: calc(var(--MARGIN) * 2); */
        height: 250px;
        background: var(--image) no-repeat center center / 60%;
    }
    .playback-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: var(--MARGIN);
        background: linear-gradient(
            180deg,
            var(--COLOR_BACKGROUND_GRADIENT1),
            var(--COLOR_BACKGROUND_GRADIENT2)
        );
    }

    .timeline {
        width: 100%;
        height: 3px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.3);
        overflow: hidden;
    }

    .timeline-progress {
        width: var(--progress);
        height: 100%;
        background: white;
    }

    .equalizer {
        width: 100%;
        height: 18px;
        margin-top: 0px;
        position: relative;
    }
    .equalizer-back {
        background: url(/img/equalizer.svg) repeat-x left / 150px;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        position: absolute;
        top: 0px;
    }
    .equalizer-front {
        background: url(/img/equalizer.svg) repeat-x left / 150px;
        width: var(--progress);
        height: 100%;
        position: absolute;
        top: 0px;
    }

    .time {
        width: 100%;
        display: flex;
        margin-top: 15px;
        justify-content: space-between;
    }

    .time div {
        font-size: 12px;
        font-weight: bold;
        color: white;
    }

    .add {
        position: absolute;
        bottom: calc(var(--MARGIN) * 2);
        right: var(--MARGIN);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .addinfo {
        font-size: 24px;
        color: white;
        font-weight: bold;
        font-style: italic;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 15px;
        padding-bottom: 5px;
    }

    .addbutton {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: var(--COLOR_BUTTON_BLUR);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
    }

    .addbuttonanimated {
        animation: heartbeat 1.5s ease-in-out infinite both;
    }

    .addbutton:active {
        background: rgba(255, 255, 255, 0.5);
        padding-top: 5px;
        box-shadow: 0px 5px inset rgb(0 0 0 / 20%);
    }

    .fiestaover {
        height: 250px;
        background: url('/img/fiestaover.svg') no-repeat top center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 50px;
    }

    .fiestaover div {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: white;
        width: 100%;
    }
    .fiestaover .smalltext {
        font-size: 12px;
        font-weight: normal;
        line-height: 20px;
    }

    @keyframes heartbeat {
        from {
            background: var(--COLOR_BUTTON_BLUR);
            transform: scale(1);
            transform-origin: center center;
            animation-timing-function: ease-out;
        }
        10% {
            background: var(--COLOR_BUTTON_BLUR);
            transform: scale(0.91);
            animation-timing-function: ease-in;
        }
        17% {
            background: var(--COLOR_BUTTON_BLUR);
            transform: scale(0.98);
            animation-timing-function: ease-out;
        }
        33% {
            background: var(--COLOR_BUTTON_BLUR);
            transform: scale(0.87);
            animation-timing-function: ease-in;
        }
        45% {
            background: var(--COLOR_BUTTON2);
            transform: scale(1);
            animation-timing-function: ease-out;
        }
    }
</style>
