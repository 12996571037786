<script>
    import { createEventDispatcher } from 'svelte'
    import { state, getToken, isMeAdmin, setActiveFiesta } from '~/store.js'
    import { VIEW, FIESTASUBVIEW, changeRoute } from '~/router.js'
    import { translate } from '~/locale/index.js'
    import Dialog from '~/components/stateless/Dialog.svelte'
    import DialogButtons from '~/components/stateless/DialogButtons.svelte'
    import DialogButton from '~/components/stateless/DialogButton.svelte'

    export let open = false
    export let name
    export let active
    export let code
    export let onClickShare

    const dispatch = createEventDispatcher()

    function onClose(e) {
        dispatch('close', e)
    }

    function onChangeName(e) {
        dispatch('changename', e)
    }

    function onLeave() {
        changeRoute(VIEW.HOME)
    }

    function onChangeSubview(subview) {
        changeRoute(VIEW.FIESTA, { code: state.fiesta.code, subview })
        onClose()
    }

    function onClickSharePrivate() {
        onClickShare()
        onClose()
    }

    async function onStop(e) {
        await setActiveFiesta({ code, token: getToken(), value: false })
        onClose(e)
    }

    async function onResume(e) {
        await setActiveFiesta({ code, token: getToken(), value: true })
        onClose(e)
    }
</script>

<Dialog {open} on:close={onClose}>
    <div class="top">
        <div class="name">
            {name}
        </div>
    </div>

    <DialogButtons>
        <DialogButton on:click={onClickSharePrivate}>
            {translate('Share')}
        </DialogButton>

        <DialogButton on:click={() => onChangeSubview(FIESTASUBVIEW.RANKING)}>
            {translate('Ranking')}
        </DialogButton>

        <DialogButton on:click={() => onChangeSubview(FIESTASUBVIEW.HISTORY)}>
            {translate('History')}
        </DialogButton>

        <DialogButton on:click={onChangeName}>
            {translate('Change name')}
        </DialogButton>

        {#if isMeAdmin() && active}
            <DialogButton className="red" on:click={onStop}>
                {translate('Stop fiesta')}
            </DialogButton>
        {:else}
            {#if isMeAdmin()}
                <DialogButton on:click={onResume}>
                    {translate('Resume fiesta')}
                </DialogButton>
            {/if}
            <DialogButton className="red" on:click={onLeave}>
                {translate('Leave fiesta')}
            </DialogButton>
        {/if}

        <DialogButton className="close" on:click={onClose}>
            {translate('Close')}
        </DialogButton>
    </DialogButtons>
</Dialog>

<style>
    .top {
        padding: 25px 25px 50px 25px;
    }

    .name {
        font-weight: bold;
        font-size: 20px;
        letter-spacing: 1px;
        color: white;
        /* background-color: var(--COLOR_BUTTON2); */
        display: inline-block;
        color: white;
        /* border-radius: 25px; */
        /* padding: 3px 15px; */
        text-align: center;
        /* background: url(/img/profile.svg); */
    }
</style>
