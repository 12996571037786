<script>
    import { createEventDispatcher } from 'svelte'
    import { translate } from '~/locale/index.js'
    import FiestaView from '~/components/stateless/FiestaView.svelte'
    import FiestaListHistory from '~/components/partials/FiestaListHistory.svelte'

    export let fiesta
    export let onClickTrack = () => {}

    const dispatch = createEventDispatcher()

    function onClose(e) {
        dispatch('close', e)
    }
</script>

<FiestaView label={translate('History')} {onClose}>
    <FiestaListHistory {fiesta} {onClickTrack} />
</FiestaView>

<style>
</style>
