<script>
    export let name
    export let me = false
    export let admin = false // ⦿
</script>

<div class="user {me && 'me'} {admin && 'admin'}">
    {name}
</div>

<style>
    .user {
        display: inline-block;
        background-color: var(--COLOR_INPUT);
        /* font-weight: bold; */
        color: white;
        font-size: 12px;
        /* min-width: 50px; */
        border-radius: 25px;
        padding: 2px 10px;
        text-align: center;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .user.me {
        background-color: var(--COLOR_TEXT_HIGHLIGHT);
    }

    .admin {
        border: 2px solid var(--COLOR_INPUT);
        box-shadow: 0px 0px 0px 2px inset black;
    }

    .admin.me {
        border-color: var(--COLOR_TEXT_HIGHLIGHT);
    }
</style>
