export function getPatchToRankTracks(tracks) {
    const counter = {}
    const patch = {}

    const list = Object.keys(tracks).map((uri) => {
        const { likes, user_id, timestamp } = tracks[uri]
        return {
            uri,
            likes: likes.length,
            user_id,
            timestamp,
        }
    })

    // Ordering by likes first
    list.sort((a, b) => b.likes - a.likes)

    // Then adding a counter of tracks by user
    list.forEach((track) => {
        const { user_id } = track
        counter[user_id] = counter[user_id] + 1 || 1
        track.counter = counter[user_id]
    })

    // Now, we can order by likes, counter, played, time
    list.sort((a, b) => a.timestamp - b.timestamp)
        .sort((a, b) => a.counter - b.counter)
        .sort((a, b) => b.likes - a.likes)

    // Creating patch
    list.forEach(({ uri }, rank) => {
        patch[uri] = { rank }
    })

    // console.log(list)

    return patch
}

export function getPatchToSubstractWinner(tracks, fn) {
    const patch = {}
    Object.keys(tracks).forEach((uri) => {
        const { rank } = tracks[uri]
        if (rank === 0) {
            patch[uri] = fn
        } else {
            patch[uri] = { rank: rank - 1 }
        }
    })

    return patch
}

export function rankUsers(users) {
    users
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .sort(
            (a, b) =>
                b.tracks_playlist +
                b.tracks_history -
                (a.tracks_playlist + a.tracks_history)
        )
        .sort(
            (a, b) =>
                b.likes_playlist +
                b.likes_history -
                (a.likes_playlist + a.likes_history)
        )
    return users
}
