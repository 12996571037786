import { createStore, TYPE } from 'dop'
import {
    localStorageGet,
    localStorageSet,
    getLocation,
    getNavigator,
} from '~/browser.js'
import { LOCALSTORAGE, NOTIFICATION_TYPE } from '~/const.js'
import { isFunction } from '~/../../shared/is.js'
import { server } from '~/server.js'
import { VIEW, changeRoute } from '~/router.js'

export const store = createStore({
    connected: false,
    location: getLocation(),
    language: getLanguage(),
    notifications: {},
})

export const { state } = store

export function setGlobalState(patch) {
    store
        .applyPatch(patch)
        .filter(({ mutations }) => mutations.length > 0)
        .forEach(({ listener, patch }) => listener(patch))
}

export function onPatch(props, fn) {
    const filter = isFunction(props)
        ? props
        : ({ path }) => props.includes(path.join('.'))

    store.subscribe((patch) => {
        if (
            patch?.fiesta?.users &&
            patch.fiesta.users.hasOwnProperty(state.user_id)
        ) {
            setName(patch.fiesta.users[state.user_id].name)
        }
        fn(patch)
    }, filter)
}

export function onConnected(fn) {
    onPatch(['connected'], (patch) => {
        if (state.connected) {
            fn()
        }
    })
}

export function getLanguage() {
    return (
        localStorageGet(LOCALSTORAGE.LANGUAGE) ||
        getNavigator().language.split('-')[0] ||
        'en'
    )
}

export function getToken() {
    return localStorageGet(LOCALSTORAGE.TOKEN)
}

export function getName() {
    return localStorageGet(LOCALSTORAGE.NAME)
}

export function setLanguage(language) {
    setGlobalState({ language })
    localStorageSet(LOCALSTORAGE.LANGUAGE, language)
}

export function setToken(token) {
    localStorageSet(LOCALSTORAGE.TOKEN, token)
}

export function setName(name) {
    localStorageSet(LOCALSTORAGE.NAME, name)
}

export async function createFiesta({ spotify_code }) {
    const { code, spotify_name } = await server.createFiesta({ spotify_code })
    if (getName() === null) {
        setName(spotify_name)
    }
    changeRoute(VIEW.FIESTA, { code })
}

export async function joinFiesta({ code, rpc }) {
    const response = await server.joinFiesta({
        code,
        token: getToken(),
        name: getName(),
        rpc,
    })
    setToken(response.token)
    setName(response.name)
    return response
}

export async function setActiveFiesta({ code, token, value }) {
    return await server.setActiveFiesta({ code, token, value })
}

export async function changeName({ code, token, name }) {
    return await server.changeName({ code, token, name })
}

export async function searchTracks({ code, token, limit, offset, q }) {
    return await server.searchTracks({ code, token, limit, offset, q })
}

export async function addTrack({ code, token, uri }) {
    return await server.addTrack({ code, token, uri })
}

export async function deleteTrack({ code, token, uri }) {
    return await server.deleteTrack({ code, token, uri })
}

export async function likeTrack({ code, token, uri }) {
    return await server.likeTrack({ code, token, uri })
}

export async function unlikeTrack({ code, token, uri }) {
    return await server.unlikeTrack({ code, token, uri })
}

export async function play({ code, token }) {
    return await server.play({ code, token })
}

export async function pause({ code, token }) {
    return await server.pause({ code, token })
}

export async function next({ code, token }) {
    return await server.next({ code, token })
}

export async function previous({ code, token }) {
    return await server.previous({ code, token })
}

export async function backward({ code, token }) {
    return await server.backward({ code, token })
}

export async function forward({ code, token }) {
    return await server.forward({ code, token })
}

export async function volumeup({ code, token }) {
    return await server.volumeup({ code, token })
}

export async function volumedown({ code, token }) {
    return await server.volumedown({ code, token })
}

export function createNotification({
    message,
    type = NOTIFICATION_TYPE.SUCCESS,
    duration = 3000,
}) {
    const now = Date.now()
    setGlobalState({
        notifications: { [now]: { message, type, duration } },
    })

    setTimeout(() => {
        setGlobalState({ notifications: { [now]: TYPE.Delete() } })
    }, duration + 500)
}

export function getNameById({ user_id }) {
    return state.fiesta.users[user_id]?.name
}

export function isMe({ user_id }) {
    return user_id === state.user_id
}

export function isMeAdmin() {
    return isUserAdmin({ user_id: state.user_id })
}

export function isUserAdmin({ user_id }) {
    return user_id === state.fiesta.admin
}

// window.createNotification = createNotification
