import { REGEX_CODE, MAX_LENGTH_NAME } from './const.js'

export function isFunction(fn) {
    return typeof fn == 'function'
}

export function isValidCode(code) {
    return REGEX_CODE.test(code)
}

export function isValidToken(token) {
    return typeof token == 'string' && token.length === 32
}

export function isValidName(name) {
    return (
        typeof name == 'string' &&
        name.length >= 2 &&
        name.length <= MAX_LENGTH_NAME
    )
}

export function isValidRpc(rpc) {
    return isFunction(rpc)
}

export function isValidSearch(search) {
    return typeof search == 'string' && search.length > 0
}

export function isValidUri(uri) {
    return /^spotify:track:[a-zA-Z0-9]+/.test(uri)
}

export function isValidTrackToAdd(fiesta, uri) {
    return !(
        fiesta.tracks.hasOwnProperty(uri) ||
        fiesta.playback.uri === uri ||
        fiesta.queued?.uri === uri
    )
}

export function isValidToUseThePlayer(fiesta, user_id) {
    return fiesta.admin === user_id /*|| fiesta.playback.user_id === user_id*/
}
