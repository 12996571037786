<script>
    // import { afterUpdate } from 'svelte'

    import TrackItem from '~/components/stateless/TrackItem.svelte'
    import ButtonCounter from '~/components/stateless/ButtonCounter.svelte'
    import { state } from '~/store.js'

    export let fiesta = null
    export let onClickTrack
    export let onLikeUnlike

    function getTotalTracks(fiesta) {
        return (
            Object.keys(fiesta.tracks).length + (fiesta.queued === null ? 0 : 1)
        )
    }

    function mergeTracks(fiesta) {
        const sum = fiesta.queued === null ? 0 : 1
        const tracks = Object.keys(fiesta.tracks).map((uri) => ({
            ...fiesta.tracks[uri],
            rank: fiesta.tracks[uri].rank + sum,
        }))
        if (fiesta.queued !== null) {
            tracks.push({ ...fiesta.queued, rank: 0 })
        }
        tracks.sort((a, b) => a.timestamp - b.timestamp)
        return tracks
    }
</script>

<div
    class="playlist"
    style="--height:calc({getTotalTracks(fiesta)} * var(--TRACK_HEIGHT))"
>
    <!-- {console.table(
        mergeTracks(fiesta).map((a) => ({
            name: a.name,
            rank: a.rank,
            t: a.timestamp,
            queued: !!a.queued_time,
        }))
    )} -->
    {#each mergeTracks(fiesta) as track}
        <div
            key={track.uri}
            class="track"
            style="--top:calc({track.rank} * var(--TRACK_HEIGHT))"
        >
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div class="track-info" on:click={() => onClickTrack(track)}>
                <TrackItem
                    image={track.image}
                    name={track.name}
                    artists={track.artists.join(', ')}
                    user={fiesta.users[track.user_id].name}
                    me={track.user_id === state.user_id}
                />
            </div>
            {#if track.queued_time === undefined}
                <div class="track-like">
                    <ButtonCounter
                        likes={track.likes.length}
                        liked={track.likes.includes(state.user_id)}
                        disabled={track.user_id === state.user_id}
                        on:click={(e) => {
                            onLikeUnlike(e, track)
                        }}
                    />
                </div>
            {:else}
                <div class="track-queued">
                    <ButtonCounter
                        likes={fiesta.queued.likes.length}
                        disabled={true}
                    />
                </div>
            {/if}
        </div>
    {/each}
</div>

<style>
    .playlist {
        margin-top: var(--MARGIN);
        height: var(--height);
        position: relative;
    }

    .track {
        /* background: var(--COLOR_BACKGROUND); */
        display: flex;
        height: var(--TRACK_HEIGHT);
        width: 100%;
        align-items: center;
        transition: top 0.5s ease;
        position: absolute;
        top: var(--top);
    }

    /* 
    .track-rank {
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
    } */

    .track-info {
        width: calc(100% - 50px);
        padding-left: var(--MARGIN);
    }

    .track-like {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .track-queued {
        background: url(/img/arrow.svg) no-repeat center / 50%;
        height: 36px;
        width: 36px;
        animation: slide-top 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
            both;
    }

    @keyframes puff-out-center {
        0% {
            transform: scale(1);
            filter: blur(0px);
            opacity: 1;
        }
        99% {
            transform: scale(2);
            filter: blur(4px);
            opacity: 0;
        }
        100% {
            transform: scale(1);
            filter: blur(0px);
            opacity: 1;
        }
    }

    *,
    *:before,
    *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    @keyframes slide-top {
        0% {
            transform: translateY(10px);
        }
        100% {
            transform: translateY(-10px);
        }
    }
</style>
