<script>
    import { createEventDispatcher } from 'svelte'

    export let likes = 0
    export let me = false
    export let icon = '/img/like.svg'
    export let icon_size = '90%'
</script>

<div class="container {me && 'me'}" style="--icon_size:{icon_size}">
    <div class="circle">
        <img src={icon} alt="like" />
    </div>
    <div class="likes">{likes}</div>
</div>

<style>
    .container {
        width: 36px;
        height: 36px;
        position: relative;
    }

    .circle {
        position: absolute;
        background: var(--COLOR_INPUT);
        border: 2px solid var(--COLOR_INPUT);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .likes {
        position: absolute;
        width: 20px;
        height: 20px;
        background: var(--COLOR_INPUT);
        border-radius: 50%;
        font-weight: 200;
        color: white;
        text-align: center;
        top: -10px;
        right: -5px;
        font-size: 14px;
        line-height: 20px;
    }

    .me .circle,
    .me .likes {
        border-color: var(--COLOR_BUTTON);
        background: radial-gradient(
            circle,
            var(--COLOR_BUTTON) 0%,
            var(--COLOR_BUTTON2) 100%
        );
    }

    .circle img {
        width: var(--icon_size);
        height: var(--icon_size);
    }
</style>
