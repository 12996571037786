const ALPHANUMERIC =
    'ModuleSymbhasOwnPr0123456789ABCDEFGHNRVfgctiUvzKqYTJkLxpZXIjQW'

const ALPHA = 'ModuleSymbhasOwnPrABCDEFGHNRVfgctiUvzKqYTJkLxpZXIjQW'

export function log(...args) {
    // console.log(new Date().toISOString().split('.')[0], ...args)
    const d = new Date()
    const f = (s) => String(s).padStart(2, '0')
    const date = `[${f(d.getMonth() + 1)}/${f(d.getDate())} ${f(
        d.getHours()
    )}:${f(d.getMinutes())}:${f(d.getSeconds())}]`
    return console.log(date, ...args)
}
log.error = function (...args) {
    return log(`ERROR:`, ...args)
}

export function randomInt(min, max) {
    const i = (Math.random() * 32768) >>> 0
    return (i % (min - (max + 1))) + min
}

export function createId(length, chars = ALPHANUMERIC) {
    let id = ''
    while (length-- > 0) {
        id += chars.charAt(randomInt(0, chars.length - 1))
    }
    return id
}

export function createUniqueId({ fn, list = [] }) {
    let id
    let fails = 0
    list = Array.isArray(list) ? list : Object.keys(list)
    do {
        id = fn()
        fails += 1
        if (fails > 10 ** id.length * 10) {
            id = id + id.slice(0, 1)
        }
    } while (list.includes(id))

    // console.log({ id, fails })
    return id
}

export function createCodeFiesta() {
    return createId(4, 'ABCDEFGHIJKLMNPQRSTUVWXYZ')
}

export function createUserId() {
    return createId(3)
}

export function createUserToken() {
    return createId(32)
}

export function createName() {
    return `Person${String(Math.random()).slice(-2)}`
}

export function timeToSecondsMinutes(time) {
    const minutes = Math.floor(time / 60)
    const seconds = time - minutes * 60

    return strPadLeft(minutes, '0', 2) + ':' + strPadLeft(seconds, '0', 2)
}

export function strPadLeft(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length)
}

export function randomError(a = [1], b = 5) {
    const n = randomInt(1, b)
    if (a.includes(n)) {
        forceError()
    }
}

export function wait(timeout = 1000) {
    return new Promise((resolve) => {
        setTimeout(resolve, timeout)
    })
}
