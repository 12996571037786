<script>
    import { createEventDispatcher } from 'svelte'
    import { translate } from '~/locale/index.js'
    import { rankUsers } from '../../../../shared/ranks.js'
    import { state, isUserAdmin, isMe } from '~/store.js'
    import FiestaView from '~/components/stateless/FiestaView.svelte'
    import User from '~/components/stateless/User.svelte'
    import Counter from '~/components/stateless/Counter.svelte'

    export let fiesta

    const dispatch = createEventDispatcher()

    function onClose(e) {
        dispatch('close', e)
    }

    function getAllLikesByUser(user_id, tracks) {
        let likes = 0
        tracks.filter((track) => {
            if (track.user_id === user_id) {
                likes += track.likes.length
            }
        })
        return likes
    }

    function orderUsers(fiesta) {
        const users = Object.keys(fiesta.users).map((user_id) => {
            const playlist = Object.keys(fiesta.tracks).map(
                (uri) => fiesta.tracks[uri]
            )
            const history = fiesta.history
            const likes_playlist = getAllLikesByUser(user_id, playlist)
            const likes_history = getAllLikesByUser(user_id, history)
            const tracks_playlist = playlist.filter(
                (track) => track.user_id === user_id
            ).length
            const tracks_history = history.filter(
                (track) => track.user_id === user_id
            ).length

            return {
                user_id,
                name: fiesta.users[user_id].name,
                likes_playlist,
                likes_history,
                tracks_playlist,
                tracks_history,
            }
        })

        return rankUsers(users)
    }
</script>

<FiestaView label={translate('Ranking')} {onClose}>
    <div class="users">
        {#each orderUsers(fiesta) as user, index}
            <div key={user.user_id} class="user">
                <div class="user-rank">#{index + 1}</div>
                <div class="user-name">
                    <User
                        name={user.name}
                        me={isMe({ user_id: user.user_id })}
                        admin={isUserAdmin({ user_id: user.user_id })}
                    />
                </div>
                <div class="user-data">
                    <div class="data">
                        <Counter
                            likes={user.likes_playlist + user.likes_history}
                            me={user.user_id === state.user_id}
                            icon="/img/like.svg"
                        />
                    </div>
                    <div class="data">
                        <Counter
                            likes={user.tracks_playlist + user.tracks_history}
                            me={user.user_id === state.user_id}
                            icon="/img/music.svg"
                            icon_size="80%"
                        />
                    </div>
                </div>
            </div>
        {/each}
    </div>
</FiestaView>

<style>
    .user {
        /* width: calc(100% - (var(--MARGIN) * 2)); */
        margin-top: calc(var(--MARGIN) * 2);
        margin-bottom: var(--MARGIN);
        margin-left: var(--MARGIN);
        margin-right: var(--MARGIN);

        display: flex;
        align-items: center;
    }

    .user-rank {
        /* width: 50px; */
        font-size: 16px;
        font-family: 'Fira Code';
        color: var(--COLOR_BUTTON_DISABLED);
    }

    .user-name {
        padding-left: var(--MARGIN);
    }

    .user-data {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }

    .data {
        transform: scale(0.8);
        margin: 0 5px;
    }
</style>
