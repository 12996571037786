<script>
    import { createEventDispatcher } from 'svelte'
    import { translate } from '~/locale/index.js'
    import {
        getNameById,
        isMe,
        isMeAdmin,
        getToken,
        deleteTrack,
    } from '~/store.js'
    import { getRoute } from '~/router.js'
    import Dialog from '~/components/stateless/Dialog.svelte'
    import DialogButtons from '~/components/stateless/DialogButtons.svelte'
    import DialogButton from '~/components/stateless/DialogButton.svelte'
    import TrackItem from '~/components/stateless/TrackItem.svelte'
    import User from '~/components/stateless/User.svelte'

    export let fiesta
    export let track = null

    const dispatch = createEventDispatcher()

    function onClose(e) {
        dispatch('close', e)
    }

    async function onDelete(e) {
        const { code } = getRoute().params
        const token = getToken()
        await deleteTrack({ code, token, uri: track.uri })
        dispatch('close', e)
    }
</script>

<Dialog open={track !== null} height="70%" on:close={onClose}>
    {#if track !== null}
        <div class="top">
            <div class="info">
                <TrackItem
                    image={track.image}
                    name={track.name}
                    artists={track.artists.join(', ')}
                    user={getNameById({
                        user_id: track.user_id,
                    })}
                    me={isMe({ user_id: track.user_id })}
                />
            </div>

            <div>
                <div class="likes">Likes</div>
                <div>
                    <div class="users">
                        {#each track.likes || [] as user_id}
                            <User
                                name={getNameById({ user_id })}
                                me={isMe({ user_id })}
                            />
                        {/each}
                    </div>

                    {#if (track.likes || []).length === 0}
                        <div class="nolikes">
                            {translate('This song does not have any like')}.
                        </div>
                    {/if}
                </div>
            </div>
        </div>

        <DialogButtons>
            {#if !track.queued && fiesta.tracks.hasOwnProperty(track.uri) && (isMe( { user_id: track.user_id } ) || isMeAdmin())}
                <DialogButton className="red" on:click={onDelete}>
                    {translate('Remove from playlist')}
                </DialogButton>
            {/if}
            <DialogButton className="close" on:click={onClose}>
                {translate('Close')}
            </DialogButton>
        </DialogButtons>
    {/if}
</Dialog>

<style>
    .top {
        padding: 15px 25px;
    }

    .likes {
        font-size: 18px;
        color: white;
        height: 30px;
        margin-top: 30px;
    }

    .users > :global(*) {
        margin-right: 5px;
    }

    .nolikes {
        text-align: center;
        font-size: 14px;
        font-weight: 200;
        opacity: 0.5;
        padding: 20px 0;
    }
</style>
