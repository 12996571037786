// https://accounts.spotify.com/authorize?response_type=token&client_id=14b38f22018b49528b9be009feee6f24&redirect_uri=https%3A%2F%2Fexample.com%2Fcallback&scope=app-remote-control%20user-read-playback-state%20user-modify-playback-state%20user-read-currently-playing%20user-read-playback-position&show_dialog=true

// { error: { status: 401, message: "Invalid access token" } }
// { error: "invalid_grant", error_description: "Invalid refresh token" }
// { error: "invalid_request", error_description: "refresh_token must be supplied" }

export function getUrlAuth({
    client_id,
    redirect_uri,
    scopes,
    state,
    response_type = 'code',
}) {
    return (
        'https://accounts.spotify.com/authorize' +
        `?response_type=${response_type}` +
        `&client_id=${client_id}` +
        `&redirect_uri=${encodeURIComponent(redirect_uri)}` +
        (Array.isArray(scopes)
            ? `&scope=${encodeURIComponent(scopes.join(' '))}`
            : '') +
        (typeof state == 'string' ? `&state=${state}` : '')
    )
}

export function getAccessToken({ code, client_id, secret_id, redirect_uri }) {
    return fetchWrapper({
        url: 'https://accounts.spotify.com/api/token',
        method: 'POST',
        Authorization: `Basic ${btoa(client_id + ':' + secret_id)}`,
        body: [
            'grant_type=authorization_code',
            `redirect_uri=${encodeURIComponent(redirect_uri)}`,
            `code=${code}`,
        ].join('&'),
    })
}

export function getFreshToken({ refresh_token, client_id, secret_id }) {
    return fetchWrapper({
        url: `https://accounts.spotify.com/api/token`,
        method: 'POST',
        Authorization: `Basic ${btoa(client_id + ':' + secret_id)}`,
        body: [
            'grant_type=refresh_token',
            `refresh_token=${refresh_token}`,
        ].join('&'),
    })
}

export function createApi({
    access_token,
    refresh_token,
    client_id,
    secret_id,
    // log = console.log,
} = {}) {
    async function fetchApi(endpoint, method, body) {
        try {
            const params = {
                url: `https://api.spotify.com/v1${endpoint}`,
                method,
                Authorization: `Bearer ${access_token}`,
            }
            if (body !== undefined) {
                params.body = body
            }
            return await fetchWrapper(params)
        } catch (e) {
            if (e.error.status === 401 && typeof refresh_token == 'string') {
                const response = await getFreshToken({
                    refresh_token,
                    client_id,
                    secret_id,
                })
                setToken(response.access_token)
                return fetchApi(endpoint, method)
            }
            throw e
        }
    }

    function setToken(token) {
        access_token = token
    }

    function getToken() {
        return access_token
    }

    function getTrack({ uri }) {
        const id = uri.split(':')[2]
        return fetchApi(`/tracks/${id}`, 'GET')
    }

    function getUser() {
        return fetchApi(`/me`, 'GET')
    }

    function getPlayback() {
        return fetchApi(`/me/player`, 'GET')
    }

    function play() {
        // '{"uris":["spotify:track:5gDOwzZqoOKrCC95VAxmf8","spotify:track:7lLCI94CimRqONLgExs8op"]}',
        return fetchApi(`/me/player/play`, 'PUT')
    }

    function pause() {
        return fetchApi(`/me/player/pause`, 'PUT')
    }

    function next() {
        return fetchApi(`/me/player/next`, 'POST')
    }

    function previous() {
        return fetchApi(`/me/player/previous`, 'POST')
    }

    function seek({ position_ms }) {
        return fetchApi(`/me/player/seek?position_ms=${position_ms}`, 'PUT')
    }

    function volume({ volume }) {
        return fetchApi(`/me/player/volume?volume_percent=${volume}`, 'PUT')
    }

    function search({ q, type = 'track', limit = 20, offset = 0 }) {
        return fetchApi(
            `/search?type=${type}&limit=${limit}&offset=${offset}&q=${encodeURIComponent(
                q
            )}`,
            'GET'
        )
    }

    function addQueue({ uri }) {
        return fetchApi(
            `/me/player/queue?uri=${encodeURIComponent(uri)}`,
            'POST'
        )
    }

    function getQueue() {
        return fetchApi(`/me/player/queue`, 'GET')
    }

    function getRecentlyPlayedTracks() {
        return fetchApi(`/me/player/recently-played`, 'GET')
    }

    return {
        setToken,
        getToken,
        getTrack,
        getUser,
        getPlayback,
        play,
        pause,
        next,
        previous,
        seek,
        volume,
        search,
        addQueue,
        getQueue,
        getRecentlyPlayedTracks,
    }
}

async function fetchWrapper({ url, method, Authorization, body }) {
    const headers = { Authorization }
    const opts = { method, headers }
    if (method === 'POST' && typeof body === 'string') {
        headers['Content-Type'] = 'application/x-www-form-urlencoded'
        opts.body = body
    }
    const response = await fetch(url, opts)

    let output
    try {
        output = await response.clone().json()
    } catch (e) {
        output = await response.text()
    }

    if (typeof output == 'string') {
        return output
    }

    if (output.hasOwnProperty('error')) {
        throw output
    }

    return output
}
