<script>
    import { createEventDispatcher, onMount } from 'svelte'
    import { translate } from '~/locale/index.js'
    import {
        searchTracks,
        state,
        addTrack,
        getToken,
        createNotification,
    } from '~/store.js'
    import { NOTIFICATION_TYPE } from '~/const.js'
    import { getError } from '~/../../shared/error.js'
    import { isValidTrackToAdd } from '~/../../shared/is.js'

    import Scroll from '~/components/stateless/Scroll.svelte'
    import TrackItemSmall from '../stateless/TrackItemSmall.svelte'

    export let code

    let timeout
    let searching = false
    let tracks = []
    let input = ''
    let limit = 20
    let offset = 0
    let offset_ended = false

    const dispatch = createEventDispatcher()
    let element_input
    let element_scroll

    onMount(() => {
        element_input = document.getElementById('input')
        element_scroll = document.getElementById('scroll')
        element_scroll.addEventListener('scroll', onScroll)
        element_input.focus()
    })

    function onClose(e) {
        onClean()
        dispatch('close', e)
        element_input.blur()
    }

    function onClean(e) {
        input = ''
        offset = 0
        offset_ended = false
        tracks = []
        element_input.focus()
    }

    async function onSearch({ target }) {
        input = target.value
        offset = 0
        offset_ended = false
        element_scroll.scrollTop = 0

        if (input.length === 0) {
            tracks = []
            return
        }

        clearTimeout(timeout)
        timeout = setTimeout(async () => {
            if (input.length > 0) {
                searching = true
                try {
                    const token = getToken()
                    const { result, ms } = await searchTracks({
                        code,
                        token,
                        limit,
                        offset,
                        q: input,
                    })

                    if (input.length === 0) {
                        tracks = []
                    } else {
                        tracks = result.tracks.items
                    }
                } catch (e) {
                    createNotification({
                        message: translate(getError(e)),
                        type: NOTIFICATION_TYPE.ERROR,
                    })
                }
                searching = false
            }
        }, 300)
    }

    async function onAddTrack({ uri, name, e }) {
        const token = getToken()
        try {
            await addTrack({ code, token, uri })
            e.target.disabled = true
            tracks = tracks // forceUpdate
            // createNotification({
            //     message: translate('${name} added to the queue', { name }),
            // })
        } catch (e) {
            createNotification({
                message: translate(e.error),
                type: NOTIFICATION_TYPE.ERROR,
            })
        }
    }

    async function onScroll() {
        const content = document.getElementById('tracks')

        if (
            input.length > 0 &&
            element_scroll.scrollTop > 0 &&
            document.activeElement === element_input
        ) {
            element_input.blur()
        }

        if (
            !searching &&
            !offset_ended &&
            input.length > 0 &&
            // tracks.length > 0 &&
            element_scroll.scrollTop + element_scroll.offsetHeight + 200 >
                content.offsetHeight
        ) {
            offset += limit
            const token = getToken()
            searching = true
            try {
                const { result } = await searchTracks({
                    code,
                    token,
                    limit,
                    offset,
                    q: input,
                })
                if (result.tracks.items.length === 0) {
                    offset_ended = true
                } else {
                    tracks = tracks.concat(result.tracks.items)
                }
            } catch (e) {
                createNotification({
                    message: translate(getError(e)),
                    type: NOTIFICATION_TYPE.ERROR,
                })
            }
            searching = false
        }
    }
</script>

<div id="fiestaviewsearch">
    <Scroll id="scroll">
        <div class="subscroll">
            <div id="tracks">
                {#each tracks as track}
                    <div class="track">
                        <div class="left">
                            <TrackItemSmall
                                name={track.name}
                                artists={track.artists
                                    .map((artist) => artist.name)
                                    .join(', ')}
                                image={track.album.images[0].url}
                            />
                        </div>
                        <div class="button">
                            <button
                                disabled={!isValidTrackToAdd(
                                    state.fiesta,
                                    track.uri
                                )}
                                on:click={(e) =>
                                    onAddTrack({
                                        uri: track.uri,
                                        name: track.name,
                                        e,
                                    })}
                            >
                                <img
                                    alt="add"
                                    src="/img/add.svg"
                                    width="16"
                                    height="16"
                                />
                            </button>
                        </div>
                    </div>
                {/each}

                {#if searching}
                    <div class="searching" />
                {/if}
            </div>
        </div>
    </Scroll>

    <div class="header">
        <div class="headercontent">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div class="back" on:click={onClose}>
                <img alt="back" src="/img/back.svg" width="32" height="32" />
            </div>
            <div class="input">
                <input
                    id="input"
                    value={input}
                    placeholder={translate('Search')}
                    type="text"
                    on:input={onSearch}
                />
            </div>
            {#if input.length > 0}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div class="clean" on:click={onClean}>
                    <svg viewBox="0 0 512 512" xml:space="preserve"
                        ><path
                            fill="black"
                            d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"
                        /></svg
                    >
                </div>
            {/if}
        </div>
    </div>
</div>

<style>
    #fiestaviewsearch {
        z-index: 2;
        position: absolute;
        top: 0;
        background: var(--COLOR_BACKGROUND);
        width: 100%;
        height: 100%;
    }

    .header {
        padding: 0 var(--MARGIN);
        background: rgba(0, 0, 0, 0.7);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
    }

    .headercontent {
        display: flex;
        align-items: center;
        position: relative;
        padding: var(--MARGIN) 0;
    }

    .back {
        margin-right: 10px;
        height: 100%;
        width: 26px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    /* .back svg {
        fill: white;
        width: 26px;
        height: 26px;
    } */

    .input {
        flex: 1;
    }

    .input input {
        background: white;
        color: black;
        border: 0;
        font-family: 'Fira Sans';
        border-radius: 100px;
        font-size: 15px;
        font-weight: bold;
        width: 100%;
        height: 35px;
        padding: 0 15px;
        outline: none;
    }

    .clean {
        position: absolute;
        right: 10px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 35px;
        width: 25px;
        justify-content: flex-end;
    }

    .clean svg {
        fill: white;
        width: 17px;
        height: 17px;
    }

    .subscroll {
        padding: var(--MARGIN);
        padding-top: calc(var(--MARGIN) * 4);
    }

    .track {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding: var(--MARGIN) 0;
    }

    .left {
        display: flex;
        width: calc(100% - 100px);
    }

    .button {
        display: flex;
        align-items: center;
    }

    .button button {
        transition: all 0.5s ease;
        background: var(--COLOR_BUTTON2);
        border-radius: 50%;
        border: 0;
        color: white;
        font-weight: bold;
        font-size: 14px;
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button button img {
        width: 20px;
        height: 20px;
    }

    .button button:disabled {
        transition: all 0.5s ease;
        /* border: 2px solid var(--COLOR_BUTTON_DISABLED); */
        background: url(/img/ok.svg) no-repeat center / 20px;
        /* background-color: var(--COLOR_SUCCESS); */
        color: transparent;
    }

    .button button:disabled img {
        display: none;
    }

    .searching {
        background: url(/img/loading.svg) no-repeat center / auto 100%;
        width: 100%;
        height: 45px;
        opacity: 1;
    }
</style>
