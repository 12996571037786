<script>
    import { state, onPatch } from '~/store.js'

    let notifications = []

    onPatch(
        ({ path }) => path[0] === 'notifications',
        () => {
            notifications = Object.keys(state.notifications)
                .map((timestamp) => {
                    return {
                        timestamp: Number(timestamp),
                        ...state.notifications[timestamp],
                    }
                })
                .sort((a, b) => a.timestamp - b.timestamp)
        }
    )
</script>

<div class="notifications">
    {#each notifications as notification}
        <div
            style="--duration:{notification.duration / 1000}s"
            class={`message ${notification.type} slide-in-bottom`}
        >
            {notification.message}
        </div>
    {/each}
</div>

<style>
    .notifications {
        pointer-events: none;
        bottom: 0;
        z-index: 2;
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }

    .message {
        margin-bottom: var(--MARGIN);
        text-align: center;
        display: inline;
        font-size: 16px;
        color: white;
        font-weight: bold;
        padding: 15px 25px;
        border-radius: 35px;
        box-shadow: 0 5px 5px var(--COLOR_BACKGROUND_GRADIENT2);
    }

    .success {
        background: var(--COLOR_SUCCESS);
    }

    .error {
        background: var(--COLOR_ERROR);
    }

    /* https://animista.net/play/entrances/slide-in/slide-in-bottom */
    .slide-in-bottom {
        animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            both;
    }

    @keyframes slide-in-bottom {
        0% {
            transform: translateY(100px);
        }
        100% {
            transform: translateY(0);
        }
    }
</style>
