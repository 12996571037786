<script>
    import { createEventDispatcher } from 'svelte'
    import QRious from 'qrious'
    import { translate } from '~/locale/index.js'
    import Dialog from '~/components/stateless/Dialog.svelte'
    import DialogButtons from '~/components/stateless/DialogButtons.svelte'
    import DialogButton from '~/components/stateless/DialogButton.svelte'
    import { getLocationObject } from '~/browser.js'

    export let open = false
    export let code

    const location = getLocationObject()
    const domain = location.host
    const visit = `${location.protocol}//${domain}`
    const url = `${visit}/${code}`
    const size = 175
    const qr = new QRious({
        value: url,
        background: 'white',
        foreground: 'black',
        size,
        level: 'H',
    }).toDataURL()

    const dispatch = createEventDispatcher()

    function onClose(e) {
        dispatch('close', e)
    }

    function onShare() {
        navigator.share({ url })
    }
</script>

<Dialog {open} on:close={onClose}>
    <div class="top">
        <div class="goto">
            {translate('Use this code on')} <strong>spotifyfiesta.com</strong>
        </div>

        <div class="code">{code}</div>

        <div class="qr" style={`--size:${size}px`}>
            <img alt={url} src={qr} />
        </div>
    </div>

    <DialogButtons>
        <DialogButton on:click={onShare}>
            {translate('Share')}
        </DialogButton>
        <DialogButton className="close" on:click={onClose}>
            {translate('Close')}
        </DialogButton>
    </DialogButtons>
</Dialog>

<style>
    .top {
        padding: 15px 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .goto {
        padding: 20px 0;
        font-weight: 400;
        font-size: 14px;
    }
    .goto strong {
        text-decoration: underline;
        color: white;
        letter-spacing: 1px;
    }

    .code {
        font-family: 'Fira Code';
        letter-spacing: 2px;
        font-size: 25px;
        color: white;
        padding: 5px 30px;
        border-radius: 35px;
        border: 3px dashed var(--COLOR_BUTTON);
    }

    .qr {
        margin-top: 20px;
    }
    .qr img {
        width: var(--size);
    }
</style>
