<script>
    import { getRoute } from '~/router.js'
    import { state, onPatch } from '~/store.js'

    import Connectivity from '~/components/partials/Connectivity.svelte'
    import Notifications from '~/components/partials/Notifications.svelte'

    let View = getRoute(state.location).view

    onPatch(['location'], () => {
        View = getRoute(state.location).view
    })

    // https://stackoverflow.com/questions/36639880/double-click-event-preventdefault-not-stopping-the-execution
    document.addEventListener('dblclick', function (e) {
        e.preventDefault()
    })
</script>

<svelte:component this={View} />
<Connectivity />
<Notifications />

<style>
</style>
