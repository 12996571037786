<script>
    export let maxwidth = 550
</script>

<div class="desktop" style="--maxwidth:{maxwidth}px">
    <div class="desktopcontent">
        <slot />
    </div>
</div>

<style>
    .desktop {
        display: flex;
        justify-content: center;

        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
    }

    .desktopcontent {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: var(--maxwidth);
    }

    .desktopcontent > :global(*) {
        pointer-events: all;
    }

    /* @media screen and (orientation: landscape) {
        .desktopcontent {
        }
    } */
</style>
