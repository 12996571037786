import { createNode } from 'dop'
import { setGlobalState } from '~/store.js'
import { URL_WS, WS_PORT } from '~/../../shared/secret.js'

const protocol = location.protocol === 'https:' ? 'wss' : 'ws'
const url =
    protocol === 'wss' ? URL_WS : `ws://${location.hostname}:${WS_PORT}/ws`
const node = createNode()

export let server = {}

function connect() {
    // clearTimeout(timeout)
    const ws = new WebSocket(url)
    console.log('connecting...')
    ws.onopen = async () => {
        console.log('connected')
        const getServerEndpoints = node.open((msg) => {
            if (ws.readyState === 1) {
                ws.send(msg)
            }
        })
        server = await getServerEndpoints()
        setGlobalState({ connected: true })
    }
    ws.onmessage = (e) => node.message(e.data)
    ws.onclose = () => {
        console.log('close')
        setGlobalState({ connected: false })
        setTimeout(connect, 500)
    }
}

connect()
