<script>
    import User from '~/components/stateless/User.svelte'

    export let name = ''
    export let artists = ''
    export let user = ''
    export let me = false
</script>

<div class="container">
    <div class="name">{name}</div>
    <div class="artists">{artists}</div>
    {#if user.length > 0}
        <div class="user"><User name={user} {me} /></div>
    {/if}
</div>

<style>
    .name {
        color: white;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .artists {
        color: var(--COLOR_TEXT_GREY);
        font-size: 17px;
        line-height: 25px;
        text-shadow: 0px 1px 0px var(--COLOR_BACKGROUND);

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-bottom: 6px;
    }

    .user {
        margin-bottom: 6px;
    }
</style>
