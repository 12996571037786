<script>
    import { createEventDispatcher } from 'svelte'
    import { isValidName } from '~/../../shared/is.js'
    import { MAX_LENGTH_NAME } from '~/../../shared/const.js'
    import { translate } from '~/locale/index.js'
    import Dialog from '~/components/stateless/Dialog.svelte'
    import DialogButtons from '~/components/stateless/DialogButtons.svelte'
    import DialogButton from '~/components/stateless/DialogButton.svelte'
    import Field from '~/components/stateless/Field.svelte'
    import Input from '~/components/stateless/Input.svelte'
    import Button from '~/components/stateless/Button.svelte'
    import { changeName, getToken } from '~/store.js'

    export let open = false
    export let code
    let name = ''

    const dispatch = createEventDispatcher()

    function onClose(e) {
        name = ''
        dispatch('close', e)
    }

    async function onChangeName(e) {
        await changeName({ code, token: getToken(), name })
        onClose(e)
    }
</script>

<Dialog {open} height="85%" on:close={onClose}>
    <div class="content">
        <!-- THIS MUST BE A COMPONENT -->
        <div class="logo" />
        <Field>
            <div class="whatsyourname">
                {translate('What is your name?')}
            </div></Field
        >

        <Field>
            <Input
                id="inputchangename"
                maxlength={MAX_LENGTH_NAME}
                bind:value={name}
            />
        </Field>
        <Field>
            <Button disabled={!isValidName(name)} on:click={onChangeName}
                >{translate('Continue')}</Button
            >
        </Field>
        <!-- THIS MUST BE A COMPONENT -->
    </div>

    <DialogButtons>
        <DialogButton className="close" on:click={onClose}>
            {translate('Cancel')}
        </DialogButton>
    </DialogButtons>
</Dialog>

<style>
    .content {
        padding-left: var(--MARGIN_CONTAINER);
        padding-right: var(--MARGIN_CONTAINER);
    }

    .logo {
        width: 100%;
        height: 135px;
        background: url(/img/logo.svg) no-repeat center 0 / 80%;
    }

    .whatsyourname {
        font-weight: bold;
        font-size: 22px;
        color: white;
        text-align: center;
    }
</style>
