<script>
    export let id = undefined
</script>

<div {id} class="scroll">
    <slot />
</div>

<style>
    .scroll {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>
