const TRANSLATIONS = {
    // ERRORS
    SPOTIFY_NOT_PREMIUM: {
        en: 'Your account is does not have Spotify premium.',
        es: 'Tu cuenta no tiene Spotify premium.',
    },
    SPOTIFY_INVALID_ACCESS: {
        en: 'We have not had access to your Spotify account.',
        es: 'No hemos tenido acceso a tu cuenta Spotify.',
    },
    USER_TRACKS_LIMIT: {
        en: 'Track limit per user reached.',
        es: 'Límite de canciones por usuario alcanzado.',
    },
    invalid_grant: {
        en: 'INVALID_GRANT: We have problems connecting with Spotify.',
        es: 'INVALID_GRANT: Estamos teniendo problemas conectando con Spotify.',
    },

    // UX
    Connecting: { es: 'Conectando' },
    Close: { es: 'Cerrar' },
    Cancel: { es: 'Cancelar' },
    Share: { es: 'Compartir' },
    JOIN: { es: 'ENTRAR' },
    'CREATE FIESTA': { es: 'CREAR FIESTA' },
    Or: { es: 'O' },
    'What is your name?': { es: '¿Cuál es tu nombre?' },
    'Spotify premium is required': { es: 'Se requiere Spotify premium' },
    'Fiesta not found': { es: 'Fiesta no encontrada' },
    'Fiesta is over': { es: 'La fiesta se ha acabado' },
    'Oops... ${user} has finished the fiesta': {
        es: 'Oops... ${user} ha terminado la fiesta',
    },
    'All the data of this fiesta will be removed in ${hours} hours': {
        es: 'Todos los datos de esta fiesta se van a eliminar en ${hours} horas',
    },
    'Back to home': { es: 'Volver al inicio' },
    'Use this code on': { es: 'Usa este codigo en' },
    'Enter your name': { es: 'Introduce tu nombre' },
    Continue: { es: 'Continuar' },
    Search: { es: 'Buscar' },
    Playlist: { es: 'Lista' },
    Add: { es: 'Añadir' },
    History: { es: 'Historial' },
    Ranking: { es: 'Gente' },
    Ranking: { es: 'Ranking' },
    '${user} is not playing music': {
        es: '${user} no está reproduciendo música',
    },
    'We have problems connecting with Spotify: ${error}': {
        es: 'Tenemos problemas conectando con Spotify: ${error}',
    },
    'Add songs to the list!': { es: 'Añade canciones a la lista' },
    '${name} added to the queue': { es: '${name} se ha añadido a la cola' },
    'Change name': { es: 'Cambiar nombre' },
    'Leave fiesta': { es: 'Salir de la fiesta' },
    'Stop fiesta': { es: 'Terminar fiesta' },
    'Resume fiesta': { es: 'Reanudar fiesta' },
    'Remove from playlist': { es: 'Quitar de la lista' },
    'This song does not have any like': {
        es: 'Esta cancion no tiene ningun voto',
    },
}

export default TRANSLATIONS
