export const ENVIRONMENT = 'prod'
export const WS_PORT = 4848
export const URL_HTTP = 'https://spotifyfiesta.com'
export const URL_WS = 'wss://spotifyfiesta.com/ws'
// export const URL_HTTP = 'http://localhost:5000'
// export const URL_WS = `ws://localhost:${WS_PORT}/ws`

export const SPOTIFY_CLIENT_ID = 'f8876a6547c946779a1bea9e38a05e1c'
export const SPOTIFY_SECRET_ID = '523f6991996644d09ceab7de51e7cca4'

