<script>
    export let name
    export let artists
    export let image
    export let history = false
</script>

<div class="track {history && 'history'}">
    <div class="image">
        <img alt={name} src={image} />
    </div>
    <div class="info">
        <div class="name">{name}</div>
        <div class="artists">
            {artists}
        </div>
    </div>
</div>

<style>
    .history img {
        filter: grayscale(0.6);
    }

    .history .name,
    .history .artists {
        color: var(--COLOR_DISABLED);
    }

    .track {
        height: 40px;
        width: 100%;
        display: flex;
    }

    .image {
        margin-right: var(--MARGIN);
    }

    .image img {
        width: 40px;
        height: 40px;
        border-radius: 2px;
    }

    .info {
        width: 100%;
    }

    .name {
        color: white;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .artists {
        color: var(--COLOR_TEXT_GREY);
        font-size: 16px;
        line-height: 24px;
        text-shadow: 1px 1px 2px var(--COLOR_BACKGROUND);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
</style>
