const TYPE = {
    GENERAL: 0,
    // VALIDATION: 1,
    SPOTIFY: 2,
}

export const SERVER_ERROR = {
    INTERNAL_ERROR: {
        type: TYPE.SPOTIFY,
        error: 'INTERNAL_ERROR',
    },

    SPOTIFY_INVALID_ACCESS: {
        type: TYPE.SPOTIFY,
        error: 'SPOTIFY_INVALID_ACCESS',
    },
    SPOTIFY_NOT_PREMIUM: {
        type: TYPE.SPOTIFY,
        error: 'SPOTIFY_NOT_PREMIUM',
    },

    INVALID_PARAMS: {
        type: TYPE.GENERAL,
        error: 'INVALID_PARAMS',
    },
    INVALID_CODE_FIESTA: {
        type: TYPE.GENERAL,
        error: 'INVALID_CODE_FIESTA',
    },
    INVALID_TOKEN: {
        type: TYPE.GENERAL,
        error: 'INVALID_TOKEN',
    },
    INVALID_NAME: {
        type: TYPE.GENERAL,
        error: 'INVALID_NAME',
    },
    INVALID_RPC: {
        type: TYPE.GENERAL,
        error: 'INVALID_RPC',
    },
    INVALID_SEARCH: {
        type: TYPE.GENERAL,
        error: 'INVALID_SEARCH',
    },
    INVALID_URI: {
        type: TYPE.GENERAL,
        error: 'INVALID_URI',
    },
    TOKEN_NOT_FOUND_IN_FIESTA: {
        type: TYPE.GENERAL,
        error: 'TOKEN_NOT_FOUND_IN_FIESTA',
    },
    TRACK_REPEATED: {
        type: TYPE.GENERAL,
        error: 'TRACK_REPEATED',
    },
    TRACK_NOT_FOUND: {
        type: TYPE.GENERAL,
        error: 'TRACK_NOT_FOUND',
    },
    TRACK_NOT_FOUND_IN_FIESTA: {
        type: TYPE.GENERAL,
        error: 'TRACK_NOT_FOUND_IN_FIESTA',
    },
    TRACK_NOT_FOUND: {
        type: TYPE.GENERAL,
        error: 'TRACK_NOT_FOUND',
    },
    TRACK_ALREADY_LIKED: {
        type: TYPE.GENERAL,
        error: 'TRACK_ALREADY_LIKED',
    },
    TRACK_NOT_LIKED_YET: {
        type: TYPE.GENERAL,
        error: 'TRACK_NOT_LIKED_YET',
    },
    TRACK_NOT_LIKEABLE_BY_OWNER: {
        type: TYPE.GENERAL,
        error: 'TRACK_NOT_LIKEABLE_BY_OWNER',
    },
    TRACK_NOT_DELETABLE_BY_OWNER: {
        type: TYPE.GENERAL,
        error: 'TRACK_NOT_DELETABLE_BY_OWNER',
    },
    YOU_ARE_NOT_ADMIN: {
        type: TYPE.SPOTIFY,
        error: 'YOU_ARE_NOT_ADMIN',
    },
    NOT_ALLOWED_TO_USE_THE_PLAYER: {
        type: TYPE.GENERAL,
        error: 'NOT_ALLOWED_TO_USE_THE_PLAYER',
    },
    SOMETHING_WENT_WRONG_USING_THE_PLAYER: {
        type: TYPE.GENERAL,
        error: 'SOMETHING_WENT_WRONG_USING_THE_PLAYER',
    },
    USER_REQUEST_LIMIT: {
        type: TYPE.GENERAL,
        error: 'USER_REQUEST_LIMIT',
    },
    USER_TRACKS_LIMIT: {
        type: TYPE.GENERAL,
        error: 'USER_TRACKS_LIMIT',
    },
}

export function getError(e) {
    if (typeof e.error == 'string') {
        return e.error
    }
    if (typeof e.message == 'string') {
        return e.message
    }
    if (typeof e.error?.message == 'string') {
        return e.error.message
    }
    if (typeof e.error?.error == 'string') {
        return e.error.error
    }

    return JSON.stringify(e)
}
