export function localStorageSet(key, value) {
    return window.localStorage.setItem(key, value)
}

export function localStorageGet(key) {
    return window.localStorage.getItem(key)
}

export function localStorageRemove(key) {
    return window.localStorage.removeItem(key)
}

export function getLocationObject() {
    return window.location
}

export function getLocation() {
    return getLocationObject().pathname
}

export function setLocation(location) {
    window.history.pushState(null, null, location)
}

export function setHref(url) {
    window.location.href = url
}

export function onPopstate(callback) {
    window.addEventListener('popstate', callback)
}

export function getNavigator() {
    return navigator
}
