<script>
    import { state, onPatch } from '~/store.js'
    import { translate } from '~/locale/index.js'

    let connected = state.connected

    onPatch(['connected'], () => {
        connected = state.connected
    })
</script>

{#if !connected}
    <div class="connectivity">
        <div class="bg" />
        <div class="text">{translate('Connecting')}</div>
    </div>
{/if}

<style>
    .connectivity {
        position: absolute;
        z-index: 99999;
        top: 0;
        width: 100%;
        padding: 10px 0;
        text-align: center;
        color: white;
        font-size: 14px;
        font-weight: bold;
        pointer-events: none;
        background-color: #707070;
        filter: grayscale(1);
        text-shadow: 1px 1px black;
        border-radius: 0 0 15px 15px;
        overflow: hidden;
    }

    .bg {
        background: url(/img/loadingbg.svg);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.2;
    }

    .text:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        animation: ellipsis steps(4, end) 2000ms infinite;
        content: '\2026';
        width: 0px;
    }

    @keyframes ellipsis {
        to {
            width: 1em;
        }
    }
</style>
