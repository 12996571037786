<script>
    import { createEventDispatcher, onMount, onDestroy } from 'svelte'

    const dispatch = createEventDispatcher()

    export let value = ''
    // export let type = 'text'
    export let placeholder = ''
    export let id = ''
    export let maxlength

    function onInput(e) {
        dispatch('input', e)
    }

    function onBlur() {
        window.scrollTo(0, 0)
        document.body.scrollTop = 0
    }

    // https://stackoverflow.com/questions/38619762/how-to-prevent-ios-keyboard-from-pushing-the-view-off-screen-with-css-or-js
    // onMount(() => {
    //     document.getElementById(id).addEventListener('blur', onBlur)
    // })
    // onDestroy(() => {
    //     if (document.getElementById(id) !== null) {
    //         document.getElementById(id).removeEventListener('blur', onBlur)
    //     }
    // })
</script>

<input
    {id}
    type="text"
    {placeholder}
    {maxlength}
    bind:value
    on:blur={onBlur}
    on:input={onInput}
/>

<style>
    input {
        background: white;
        color: var(--COLOR_BUTTON2);
        /* box-shadow: 4px 4px 1px inset var(--COLOR_SHADOW_WHITE); */
        border: 0;
        font-family: 'Fira Sans';
        border-radius: 100px;
        font-weight: bolder;
        font-size: 20px;
        width: 100%;
        height: 50px;
        padding: 0 15px;
        outline: none;
    }
</style>
