<script>
    import TrackItemSmall from '~/components/stateless/TrackItemSmall.svelte'
    import { state } from '~/store.js'

    export let fiesta = null
    export let onClickTrack

    function toTime(timestamp) {
        const str = new Date(timestamp).toTimeString().split(':')
        return `${str[0]}:${str[1]}`
    }
</script>

<div class="history">
    {#each fiesta.history as track, index}
        {#if !(index === fiesta.history.length - 1 && track.uri === fiesta.playback.uri)}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
                key={track.uri}
                class="track"
                on:click={() => onClickTrack(track)}
            >
                <div class="track-top">
                    <TrackItemSmall
                        image={track.image}
                        name={track.name}
                        artists={track.artists.join(', ')}
                        history={true}
                    />
                </div>
                <div class="track-bottom">
                    <div>
                        <div class="oval">{toTime(track.played)}</div>
                    </div>
                    <div>
                        <div class="oval">
                            <div class="like" />
                            <div>{track.likes.length}</div>
                        </div>
                    </div>
                    <div>
                        <div
                            class="oval user {track.user_id === state.user_id &&
                                'me'}"
                        >
                            {fiesta.users[track.user_id].name}
                        </div>
                    </div>
                </div>
            </div>
        {/if}
    {/each}
</div>

<style>
    .track {
        width: calc(100% - (var(--MARGIN) * 2 + var(--MARGIN)));
        padding: var(--MARGIN);
    }

    .track-top {
        width: 100%;
    }

    .track-bottom {
        margin-top: 5px;
        display: flex;
        align-items: center;
        padding-left: 55px;
    }
    .track-bottom > div {
        margin-right: 5px;
        display: inline-block;
    }

    .oval {
        border: 1px solid var(--COLOR_INPUT);
        color: white;
        font-size: 10px;
        border-radius: 25px;
        padding: 2px 10px;
        text-align: center;
        opacity: 0.8;
        display: flex;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .oval .like {
        width: 10px;
        height: 10px;
        background: url(/img/like.svg) -3px -1px / 14px;
    }

    .oval.user {
        background-color: var(--COLOR_INPUT);
    }

    .oval.me {
        background-color: var(--COLOR_TEXT_HIGHLIGHT);
        border-color: var(--COLOR_TEXT_HIGHLIGHT);
    }
</style>
