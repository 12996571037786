import template from 'lodash.template'
import { state } from '~/store.js'
import TRANSLATIONS from '~/locale/translations.js'

export function translate(text, args = {}) {
    const { language } = state
    const translated = getTranslation(text, language)
    try {
        return supplant(translated, args)
    } catch {
        return translated
    }
}

export function getTranslation(text, language = 'en') {
    // if (language === 'en' || text.length === 0) return text
    const text_trimed = text.trim()
    if (
        TRANSLATIONS.hasOwnProperty(text_trimed) &&
        TRANSLATIONS[text_trimed].hasOwnProperty(language)
    ) {
        return TRANSLATIONS[text_trimed][language]
    }
    if (language !== 'en') {
        console.log(`TRANSLATION NOT FOUND
"${text_trimed}": {${language}: ''},`)
    }
    return text
}

export function supplant(text, args) {
    const compiled = template(text)
    return compiled(args)
}
