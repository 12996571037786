import { setLocation, getLocation, onPopstate } from '~/browser.js'
import { setGlobalState } from '~/store.js'
import Home from '~/components/views/Home.svelte'
import Fiesta from '~/components/views/Fiesta.svelte'

export const VIEW = {
    FIESTA: 'FIESTA',
    HOME: 'HOME',
}

export const FIESTASUBVIEW = {
    SEARCH: 'search',
    HISTORY: 'history',
    RANKING: 'ranking',
}

const routes = [
    // {
    //     name: VIEW.FIESTASEARCH,
    //     view: Search,
    //     test: (location) =>
    //         /^(?<code>[A-Z]{4})\/search$/.exec(location.slice(1)),
    //     build: ({ code }) => `/${code}/search`,
    // },
    {
        name: VIEW.FIESTA,
        view: Fiesta,
        test: (location) =>
            /^(?<code>[A-Z]{4})(\/(?<subview>[a-z]+))?/.exec(location.slice(1)),
        build: ({ code, subview }) => `/${code}${subview ? '/' + subview : ''}`,
    },
    {
        name: VIEW.HOME, // default
        view: Home,
        test: () => true,
        build: () => `/`,
    },
]

export function getRoute(location = getLocation()) {
    for (let index = 0; index < routes.length; ++index) {
        const { test } = routes[index]
        const match = test(location)
        if (match) {
            const params = match.groups || {}
            return { ...routes[index], params }
        }
    }
}

export function changeRoute(name, params = {}) {
    const route = routes.find((r) => r.name === name)
    const location = route.build(params)
    setLocation(location)
    setGlobalState({ location })
}

onPopstate((e) => {
    setGlobalState({ location: getLocation() })
})
